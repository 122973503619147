<template>
  <CyMenu
    :disabled="loadingEnvironmentsConfig || _.some(loadingPipeline, Boolean)"
    bottom
    content-class="env-menu py-2"
    data-cy="env-menu"
    min-width="270"
    offset-y
    origin="top right"
    transition="slide-y-transition"
    @input="(isOpened) => searchTerm = (isOpened ? '' : searchTerm)">
    <template #activator="{ on, value: isMenuOpen }">
      <div
        v-ripple
        :class="['env-menu__activator', {
          'env-menu__activator--active': isMenuOpen,
          'disabled': loadingEnvironmentsConfig || _.some(loadingPipeline, Boolean),
        }]"
        data-cy="env-menu-activator"
        v-on="on">
        <CyAvatar
          class="env-menu__avatar mr-3"
          icon-size="24"
          :item="currentEnv"/>
        <span
          class="env-menu__text mr-1"
          data-cy="env-name">
          {{ currentEnv.canonical }}
        </span>
        <v-icon
          class="env-menu__icon mr-n1"
          size="20">
          unfold_more
        </v-icon>
      </div>
    </template>

    <div @click.stop="">
      <v-text-field
        v-model.trim="searchTerm"
        class="env-menu__search-field mx-2 mb-2 pa-0"
        data-cy="search-field"
        :placeholder="$t('environment.find')"
        height="40px"
        hide-details
        autofocus
        prepend-icon="search"/>
    </div>

    <CyButton
      class="env-menu__create-btn mb-1"
      data-cy="create-env-btn"
      variant="tertiary"
      theme="primary"
      height="40px"
      icon="add"
      :to="{
        name: 'addServiceEnv',
        params: {
          mode: 'add-env',
          envCanonical: $route.params.envCanonical,
          backRouteTo: $route.name,
        },
      }">
      {{ $t('environment.create') }}...
    </CyButton>

    <v-divider class="my-2 mx-0"/>

    <v-list
      v-if="!_.isEmpty(filteredEnvs)"
      dense
      nav
      data-cy="env-list"
      class="env-menu__envs">
      <div
        v-for="env in filteredEnvs"
        :key="env.id">
        <v-list-item
          v-hover-siblings
          data-cy="envs-list-item"
          @click="goToEnv(env)">
          <v-list-item-action>
            <CyAvatar
              :item="env"
              sm/>
          </v-list-item-action>

          <v-list-item-content>
            <v-list-item-title>
              {{ env.canonical }}
            </v-list-item-title>
          </v-list-item-content>

          <CyTag
            v-if="env.paused"
            class="ml-1"
            small
            variant="default">
            {{ $t('pipeline.paused') }}
          </CyTag>

          <v-list-item-action class="mr-0">
            <v-icon
              v-if="currentEnv.canonical === env.canonical"
              size="18">
              check
            </v-icon>
          </v-list-item-action>
        </v-list-item>
      </div>
    </v-list>

    <div
      v-else
      data-cy="no-envs-found"
      class="env-menu--empty px-4 py-2">
      <div v-text="$t('notFound')"/>
    </div>
  </CyMenu>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'CyEnvironmentMenu',
  props: {
    pipelines: {
      type: Array,
      required: true,
    },
    currentEnv: {
      type: Object,
      required: true,
    },
    envs: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    searchTerm: '',
  }),
  computed: {
    ...mapState('organization/project', {
      loadingEnvironmentsConfig: (state) => state.fetchInProgress.environmentsConfig,
    }),
    ...mapState('organization/project/pipeline', {
      loadingPipeline: (state) => state.fetchInProgress,
    }),
    filteredEnvs () {
      const pausedStatusByCanonical = this.pipelines
        .reduce((acc, { environment: { canonical }, paused }) => {
          acc[canonical] = paused
          return acc
        }, {})
      const envsUpdated = _.map(this.envs, (env) => ({
        ...env,
        paused: pausedStatusByCanonical[env.canonical],
      }))

      return envsUpdated
        .filter(({ canonical }) => canonical.toLowerCase().includes(this.searchTerm?.toLowerCase()))
    },
  },
  methods: {
    goToEnv (env) {
      if (this.currentEnv.canonical === env.canonical) return
      const pipeline = _.find(this.pipelines, ({ environment }) => environment.canonical === env.canonical)
      this.$emit('update:currentEnv', env)
      this.$router.replace({
        name: this.$route.name,
        params: {
          envCanonical: env.canonical,
          pipelineCanonical: pipeline.name,
        },
      })
    },
  },
  i18n: {
    messages: {
      en: {
        notFound: 'No matching environments found',
      },
      es: {
        notFound: 'No se encontraron entornos coincidentes',
      },
      fr: {
        notFound: 'Aucun environnement correspondant trouvé',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.env-menu {
  &__actions {
    .v-icon {
      color: inherit;
    }
  }

  &__activator {
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: 2px;
    cursor: pointer;

    &.disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }

    &--active,
    &:hover,
    &:focus-visible {
      background-color: cy-get-color("primary", "light-4");
    }

    &--with-border {
      border: dashed 1px cy-get-color("primary", "light-1");
    }

    .v-icon {
      pointer-events: none;
    }
  }

  &__icon {
    color: cy-get-color("primary", "light-3");
  }

  &__avatar.cy-avatar {
    width: 24px;
    min-width: auto;
    height: 24px;

    ::v-deep .cy-avatar__initials {
      font-size: 12px;
    }
  }

  &__root-chip {
    margin-bottom: 2px;
    border-radius: 3px;
  }

  &__subtitle {
    color: cy-get-color("primary", "light-2");
    font-size: $font-size-xs;
    font-style: italic;
    font-weight: $font-weight-default;
  }

  &__text {
    font-weight: $font-weight-bolder;
    line-height: 1;
  }

  &__search-field {
    display: flex;
    align-items: center;
    border-radius: 2px;
    background: cy-get-color("primary", "light-5");
    color: cy-get-color("primary", "light-3");

    ::v-deep .v-input {
      &__prepend-outer {
        margin-left: 12px;
      }

      &__slot {
        &::before {
          display: none;
        }

        &::after {
          display: none;
        }
      }
    }
  }

  &__create-btn {
    display: flex;
    justify-content: flex-start;
    margin: 0 8px;
    font-weight: $font-weight-default !important;

    &:hover {
      background: cy-get-color("primary", "light-5") !important;
    }
  }

  &--empty {
    max-width: 264px;
  }

  &__envs {
    max-width: 270px;
    max-height: 75vh;
  }

  .v-list-item {
    height: 40px;
    padding-right: 8px;
    padding-left: 8px;
    border-radius: 2px !important;
    color: cy-get-color("primary", "main") !important;
    cursor: pointer;

    &__title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
